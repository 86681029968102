import { dataService } from '../lib/dataService'
import { ref } from 'vue'

const pedido = ref(null as unknown)
const pedidos = ref(null as unknown)
const multiPedido = ref(null as unknown)
const cliente = ref(null as unknown)
const cartTotal = ref(null as unknown)
const shipping = ref(null as unknown)
const otp = ref('')
const isMultiple = ref(false)
const status = ref({
    recibido: false,
    enFila: false,
    enProceso: false,
    impreso: false,
    imprimiendo: false,
    envio: false,
    enviado: false,
    entregado: false,
    cancelado: false,
    reposicion: false,
    defectuoso: false,
    urgente: false,
    listo: false,
    cerrado: false,
})

const isPrinting = (ColaImpresion: any, enCola: boolean) => {
    // si cualquier elemento de la cola de impresion el valo Step es Printing
    // entonces se esta imprimiendo
    let res = false
    if(!ColaImpresion) return res
    ColaImpresion.forEach((element: any) => {
        if((element.Step === 'Printing' || element.Step === 'Printed') && enCola) {
            res = true
        }
    })
    return res
}

const setStatus = (pedido: any) => {



    if(pedido.Pedidos){
        status.value.imprimiendo = true
        for(const onePedido of pedido.Pedidos){
            status.value.recibido = true
            if(!onePedido.Recibido){
                status.value.recibido = false
            }
            if(!isPrinting(onePedido.ColaImpresion, onePedido.EnCola)){
                status.value.imprimiendo = false
            }
        }
    } else {
        status.value.imprimiendo = isPrinting(pedido.ColaImpresion, pedido.EnCola)
        status.value.recibido = pedido.Recibido
    }

    status.value.enFila = pedido.EnCola
    status.value.enProceso = pedido.InProcess
    status.value.impreso = pedido.Impreso
    status.value.envio = pedido.Envio
    status.value.enviado = pedido.Enviado
    status.value.entregado = pedido.Entregado
    status.value.cancelado = pedido.Cancelado
    status.value.reposicion = pedido.Reposicion
    status.value.defectuoso = pedido.Problematic
    status.value.urgente = pedido.Urgente
    status.value.listo = pedido.Ready
    status.value.cerrado = pedido.Cerrado
}

type isShipping = {quotes: any[], guias: any[]}

const quotesLabels = (shipping: isShipping) => {
    const listaFinal = shipping.quotes.map((quote: any) => {
        if(quote.created){
            const guia = shipping.guias.find((envio: any) => envio.labelId === quote.labelId)
            if(guia){
                quote.provider = guia.provider
                quote.service_level_name = guia.service_level_name
                quote.service_level_code = guia.service_level_code
                quote.tracking_number = guia.tracking_number
                quote.label_url = guia.label_url
                quote.created_at = guia.created_at
                quote.out_of_area = guia.out_of_area
                quote.days = guia.days
            }
        }
        return quote
    })
    return listaFinal
}

const getPedido = async(otp_a: string) => {
    const endpoint = 'prisma/pedido/'
    try{
        const response = await dataService(endpoint, { otp: otp_a }).fetchPost()
        if(response === 404){
            return
        }
        otp.value = otp_a
        if(response.Pedidos){
            pedidos.value = response.Pedidos
            multiPedido.value = response
            isMultiple.value = true
        }
        else{
            pedido.value = response
            isMultiple.value = false
        }
        cliente.value = response.Cliente
        cartTotal.value = response.Data.CartTotal
        shipping.value = quotesLabels(response.Data.shipping)
        setStatus(response)
    } catch (error) {
        console.log(error)
    }
    
}


export const usePedido = (otp_a: string) => {
    getPedido(otp_a)
    return { pedido, pedidos, cliente, isMultiple, cartTotal }

}

export const estatusPago = () => {
    let totales = {} as Totales
    let id = ''
    let type = ''
    if(isMultiple.value){
        id = multiPedido.value.id
        type = 'multiple'
        totales = { Cantidad_Pagada: Number(multiPedido.value.Cantidad_Pagada), Faltante: Number(multiPedido.value.Faltante), Total: Number(multiPedido.value.Total) }
    } else {
        id = pedido.value.id
        type = 'single'
        totales = { Cantidad_Pagada: Number(pedido.value.Cantidad_Pagada), Faltante: Number(pedido.value.Faltante), Total: Number(pedido.value.Total) }
    }
    return { totales, id, type }
}

export const usePedidoDetails = () => {
    return { pedido, pedidos, cliente, isMultiple, cartTotal, shipping }
}

export const useStatus = () => {
    // sin envio:
    // 1. recibido -> 2. enFila -> 3. impreso -> 4. enProceso -> 5. listo -> 6. entregado
    // con envio:
    // 1. recibido -> 2. enFila -> 3. impreso -> 4. enProceso -> 5. listo -> 6. enviado -> 7. entregado
    const currentStatus = { paso: 0, pasos: 6, status: 'Nuevo', mensaje: 'Esperando y/o validanto tus archivos' }
    if(status.value.envio) {
        currentStatus.pasos = 7
    }
        if(status.value.recibido) {
            currentStatus.paso = 1
            currentStatus.status = 'Recibido'
            currentStatus.mensaje = 'Preparando para impresión'
        }
        if(status.value.enFila) {
            currentStatus.paso = 2
            currentStatus.status = 'En Fila'
            currentStatus.mensaje = 'En fila de impresión'
        }
        if(status.value.imprimiendo) {
            currentStatus.paso = 3
            currentStatus.status = 'Imprimiendo'
            currentStatus.mensaje = 'Estamos imprimiendo tu pedido'
        }
        if(status.value.enProceso && status.value.impreso) {
            currentStatus.paso = 4
            currentStatus.status = 'En Proceso'
            currentStatus.mensaje = 'Preparando, Cortando y Envolviendo'
        }
        if(status.value.envio){
            if(status.value.listo) {
                currentStatus.paso = 5
                currentStatus.status = 'Listo'
                currentStatus.mensaje = 'Preparando para envío'
            }
            if(status.value.enviado) {
                currentStatus.paso = 6
                currentStatus.status = 'Enviado'
                currentStatus.mensaje = 'Pedido enviado en camino a tu dirección'
            }
            if(status.value.entregado) {
                currentStatus.paso = 7
                currentStatus.status = 'Entregado'
                currentStatus.mensaje = 'Pedido entregado'
            }
        } else {
            if(status.value.listo) {
                currentStatus.paso = 5
                currentStatus.status = 'Listo'
                currentStatus.mensaje = 'Pedido listo para entrega'
            }
            if(status.value.entregado) {
                currentStatus.paso = 6
                currentStatus.status = 'Entregado'
                currentStatus.mensaje = 'Pedido entregado'
            }
        }


    return { status, currentStatus }
}