<template>
  <div class="bg-gray-300 dark:bg-neutral-900">
    <div class="flex place-content-end py-4 px-10 h-14 max-w-5xl mobile:hidden">
      <button
        data-collapse-toggle="navbar-multi-level"
        type="button"
        class="w-5 h-5 text-sm text-gray-500 rounded-lg  dark:text-gray-400  dark:focus:ring-gray-600"
        aria-controls="navbar-multi-level"
        aria-expanded="false"
        @click="isHidden = !isHidden"
      >
        <span class="sr-only">Open main menu</span>
        <svg
          class="w-5 h-5"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 17 14"
        >
          <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M1 1h15M1 7h15M1 13h15"
          />
        </svg>
      </button>
    </div>
    <div
      id="navbar-multi-level"
      :class="isHidden ? 'hidden h14 mobile:block': 'border-b-4 border-gray-300 dark:border-neutral-900'"
      class="w-full text-center mx-auto max-w-5xl px-0 mobile:pe-10"
    >
      <ul class="flex flex-col place-content-end font-medium mobile:flex-row">
        <li
          :class="page.name === 'Resumen' ? 'bg-gray-200 dark:bg-neutral-800' : 'dark:hover:bg-slate-800'"
          class="dark:text-white text-gray-700 px-5 py-4 cursor-pointer"
          @click="router.push({ path: 'resumen'}), isHidden = true"
        >
          Resumen
        </li>
        <li
          :class="page.name === 'Estatus' ? 'bg-gray-200 dark:bg-neutral-800' : 'dark:hover:bg-slate-800'"
          class="dark:text-white text-gray-700 px-5 py-4 cursor-pointer"
          @click="router.push({ path: 'estatus'}), isHidden = true"
        >
          Estatus
        </li>
      </ul>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ref, watch, computed } from 'vue'
import { useRouter } from 'vue-router'
import { useViewSize } from '@/composable/useViewSize'

const router = useRouter()
const page = computed(() => router.currentRoute.value)

const { windowSize } = useViewSize()
const isHidden = ref(true)


watch(() => windowSize.value, (newValue) => {
  if(newValue === 'mobile') {
    isHidden.value = true
  }
})

</script>