import http from '../http-common'

export const dataService = (enpoint: string, payload?: unknown) => {
    
    const fetchPost = async() => {
        try {
            const response = await http.post(enpoint, payload)
            return response.data
        } catch (error) {
            // reditect to 404 page
            window.location.href = '/404'
            return 404
        }
    }

    const postRaise = async() => {
        try {
            const response = await http.post(enpoint, payload)
            return response.data
        } catch (error) {
            console.log(error)
    } }

    return { fetchPost, postRaise }
}