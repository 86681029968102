<template>
  <div>
    <header>
      <top-menu />
    </header>
    <header
      v-if="!menuBehavior.isHidden && !menuBehavior.inLine"
      class="fixed top-0 inset-x-0 z-50 w-full border border-gray-100 bg-white/80 dark:bg-neutral-700 dark:border-neutral-900 py-3 shadow backdrop-blur-lg"
    >
      <top-menu />
    </header>
  </div>
</template>
<script lang="ts" setup>
import { useWindowScroll } from '@vueuse/core'
import { watch, ref } from 'vue'
import topMenu from './topMenu.vue'


const { y } = useWindowScroll()
const menuBehavior = ref({
    inLine: true,
    isHidden: false,
})

watch(() => y.value, (newValue, oldValue) => {
  if (newValue > 300 ) {
    menuBehavior.value.inLine = false
  }
  if (newValue === 0) {
    menuBehavior.value.inLine = true
  }
  if (newValue > oldValue) {
    menuBehavior.value.isHidden = true
  } else {
    menuBehavior.value.isHidden = false
  }
})
</script>