import { useWindowSize } from '@vueuse/core'
import { computed } from 'vue'

const  { width } = useWindowSize()

export const useViewSize = () => {
    const sizes = [
        { name: 'mobile', size: 480},
        { name: 'sm', size: 640 },
        { name: 'md', size: 768 },
        { name: 'lg', size: 1024 },
        { name: 'xl', size: 1280 },
        { name: '2xl', size: 1536 },
    ]
    const windowSize = computed(() => sizes.find(size => width.value < size.size)?.name || '2xl' )
   
    const minimalSize = computed(() => width.value < 365)

    return { windowSize, minimalSize }
}