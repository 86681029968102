import axios, { AxiosInstance } from 'axios'

type ApiConfig = {
  withCredentials: boolean
  headers: {
    'Content-Type': string
  }
  baseURL: string
  media: string
}

const baseConfig = () => {
    return {
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
      },
      baseURL: import.meta.env.VITE_API,
      media: import.meta.env.VITE_MEDIA,
    } as ApiConfig
  }

const http = axios.create(baseConfig()) as AxiosInstance

export default http
