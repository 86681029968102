import { createRouter, createWebHistory, Router } from 'vue-router'
import { RouteLocationNormalized } from 'vue-router'

const routes = [
    {
      path: '/:pathMatch(.*)*',
      redirect: '/404',
    },
    {
      path: '/',
      redirect: '/404',
    },
    {
      path: '/404',
      name: 'NotFound',
      component: () => import('../views/NotFound.vue'),
    },
    {
      path: '/pedido/:otp/',
      name: 'Pedido',
      component: () => import('../views/pedidoPage.vue'),
      meta: { base: 'pedido'},
      children: [
        {
          path: 'resumen',
          name: 'Resumen',
          component: () => import('@/views/viewsPedido/resumenPage.vue'),
        },
        {
          path: 'estatus',
          name: 'Estatus',
          component: () => import('@/views/viewsPedido/estadoPage.vue'),
        },
        {
          path: 'success',
          name: 'payStatus success',
          component: () => import('@/views/statusView.vue'),
        },
        {
          path: 'declined',
          name: 'payStatus declined',
          component: () => import('@/views/statusView.vue'),
        },
        {
          path: 'pending',
          name: 'payStatus pending',
          component: () => import('@/views/statusView.vue'),
        },
      ],
    },
    {
      path: '/pedido/:otp/:pathMatch(.*)*',
      redirect: (to: RouteLocationNormalized) => {
        return { name: 'Pedido', params: { otp: to.params.otp } }
      },
    },
  ]
  
  const router: Router = createRouter({
    history: createWebHistory(),
    routes,
  })
  
  
  export default router
  