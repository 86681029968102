import { createApp } from 'vue'
import FloatingVue from 'floating-vue'
import App from './App.vue'
import router from './lib/router'
import './index.css'
import 'floating-vue/dist/style.css'

const app = createApp(App).use(router).use(FloatingVue)

app.mount('#app')
