<template>
  <div class="flex flex-col h-screen">
    <magic-menu v-if="page.meta && page.meta.base === 'pedido'" />
    <div
      class="my-6 mt-5 px-3 sm:px-10 grow"
    >
      <alertas class="max-w-5xl mx-auto" />
      <div class="flex flex-col min-h-full max-w-5xl mx-auto">
        <router-view />
      </div>
    </div>
    <div class="text-gray-600 bg-gray-300 dark:bg-neutral-900">
      <pageFooter />
    </div>
  </div>
</template>

<script lang="ts" setup>
import pageFooter from './views/pageFooter.vue'
import magicMenu from '@/components/magicMenu.vue'
import alertas from '@/views/Alerta.vue'
import { useRouter } from 'vue-router'
import { computed } from 'vue'

const router = useRouter()
const page = computed(() => router.currentRoute.value)

</script>
